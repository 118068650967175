// import axios from "axios";
import { hanatourApi } from '../main.js';

export default class EventService {
    getEvents() {
        return hanatourApi
            .get("assets/demo/data/events.json")
            .then(res => res.data.data);
    }
}
